import React, { FC } from 'react';

import { Relative, Background } from '@custom-components/Section';
import { mapImage, usePlaceholderComponentsForRendering } from '@sitecore/common';
import { SectionRendering } from '@sitecore/types/manual/Section';
import { PageGrid, Box, Stack, Text, Image, Stretch, Grid } from '@sparky';

const Section: FC<SectionRendering> = ({ params, fields }) => {
  const { backgroundColor, textColor } = params ?? {};
  const imageProps = mapImage(fields?.backgroundImage);

  const sectionComponents = usePlaceholderComponentsForRendering('jss-section-main');

  const lastChild = sectionComponents[sectionComponents.length - 1];
  const isLastChildCenteredWithDivider =
    lastChild?.props?.children?.props?.rendering?.componentName === 'Centered' &&
    lastChild?.props?.children?.props?.rendering?.params?.hasDivider === 'true';
  const isFollowedByNegativeMargin = params?.isFollowedByNegativeMargin === 'true';

  return (
    <Relative>
      <Box
        paddingY={isFollowedByNegativeMargin ? { initial: '10', md: '16', xl: '6' } : { initial: '10', md: '16' }}
        paddingBottom={
          isLastChildCenteredWithDivider
            ? { initial: '0', md: '0' }
            : isFollowedByNegativeMargin
              ? { initial: '10', md: '16', xl: '32' }
              : { initial: '10', md: '16' }
        }
        id={params?.anchorId}>
        <Background backgroundColor={backgroundColor}>
          {imageProps.src ? (
            <Image
              src={imageProps.src}
              alt={imageProps.alt}
              width="100%"
              height="100%"
              objectFit="cover"
              sources={imageProps.sources}
            />
          ) : null}
        </Background>
        <PageGrid>
          <Stretch width>
            <PageGrid.Item gridColumn="1/-1">
              <Text color={textColor} as="div">
                <Stack gap={{ initial: '10', md: '16' }} direction="column">
                  {sectionComponents.map((child: JSX.Element, key: number) => {
                    const isTitleTextCTA = child?.props?.children?.props?.rendering?.componentName === 'TitleTextCTA';

                    if (isTitleTextCTA) {
                      return (
                        <Grid columns="12" key={key}>
                          <Grid.Item
                            gridColumn={{
                              initial: '1/-1',
                              md: 'span 8',
                            }}>
                            {child}
                          </Grid.Item>
                        </Grid>
                      );
                    }
                    return child;
                  })}
                </Stack>
              </Text>
            </PageGrid.Item>
          </Stretch>
        </PageGrid>
      </Box>
    </Relative>
  );
};

export default Section;
